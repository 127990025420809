<template>
  <div class="px-6" pt="7 md:12">
    <div text="xl md:2xl center black" m="b-2.5 md:b-6" font="700">
      {{ t('key.home.trust.partner') }}
    </div>
    <div class="xl:gapy-y-4 grid grid-cols-3 gap-x-2 gap-y-2 lg:grid-cols-5 md:grid-cols-4 md:gap-y-8">
      <div
        v-for="value in brandInfo" :key="value"
        w="full"
        bg="#F6F6F6 md:transparent"
        flex="~ justify-center items-center"
        rounded="md"
        p="1.5"
      >
        <van-image :src="value" h="8 md:15">
          <template #loading>
            <div h="8 md:15" class="w-full rounded-md bg-#F6F6F6"></div>
          </template>
        </van-image>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const isMobile = useMobileByScreen()
const store = useCommonStore()

const brandInfo = computed(() => store.commonData.brandInfo?.slice(0, isMobile.value ? 9 : 10) || [])
</script>

<style>
:root:root {
  --van-grid-item-content-active-color: none;
}

.az-grid .van-grid-item__content {
    padding: 5px;
}
</style>
